import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './AppToolbar.scss';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { createSetPropertyAction, changePage } from '../code-base/action-types';
import { AppMenu } from '../AppMenu/AppMenu';
import { InstallPrompt } from '../InstallPrompt/InstallPrompt';
import { appSlice } from '../code-base/reducers/app-state.reducer';

interface IAppToolbarState {
    autoScroll: boolean;

    /** Boolean value indicating whether or not the user is a transcriber. */
    isTranscriber: boolean;

    /** Boolean value indicating whether or not the button menu should be shown. */
    showMenu: boolean;
}
export interface IAppToolbarProps { }

export class AppToolbar extends React.Component<IAppToolbarProps, IAppToolbarState>{
    constructor(props: IAppToolbarProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        let appState = store.getState();
        this.state = {
            autoScroll: appState.appSettings.streamSettings.automaticScrolling,
            isTranscriber: appState.isTranscriber,
            showMenu: appState.appSettings.appearanceSettings.showMenu,
        };
    }

    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }

        let appState = store.getState();
        this.setState(p => ({
            isTranscriber: appState.isTranscriber,
            autoScroll: appState.appSettings.streamSettings.automaticScrolling,
            showMenu: appState.appSettings.appearanceSettings.showMenu,
        }));
    }

    private unsubscribeFromStore: Unsubscribe;

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    componentDidMount(): void {
        this.mounted = true;
    }

    componentWillUnmount(): void {
        this.unsubscribeFromStore();
    }

    /** Toggles the view scrolling. */
    toggleScrolling() {
        // Get the state of scrolling now.
        let appState = store.getState();
        let isScrolling = appState.appSettings.streamSettings.automaticScrolling;
        // Update the state to show the settings.
        store.dispatch(createSetPropertyAction('appSettings.streamSettings.automaticScrolling', !isScrolling));
    }

    /** Toggles whether or not the menu button is shown. */
    toggleMenu() {
        // Get the state of scrolling now.
        let appState = store.getState();
        let showMenu = appState.appSettings.appearanceSettings.showMenu;
        // Update the state to show the settings.
        store.dispatch(createSetPropertyAction('appSettings.appearanceSettings.showMenu', !showMenu));
    }

    showSettingsClicked(): void {
        // Update the state to show the settings.
        store.dispatch(createSetPropertyAction('showAppSettings', true));
    }

    /** Navigates to the event list. */
    navigateToList(): void {
        // store.dispatch(changePage({ pageName: 'event-list' }));
        store.dispatch(appSlice.actions.changePage({ pageName: 'event-list' }));
    }

    /** Called when the user clicks the home button. */
    homeButtonClicked(): void {
        // store.dispatch(changePage({ pageName: 'enter-code' }));
        store.dispatch( appSlice.actions.changePage({ pageName: 'enter-code' }));
    }

    getRegularToolbar(): React.ReactNode {
        return (
            <Toolbar>
                {/* <div className="p-toolbar-group-left">
                    <img id="logo" src="captionmax-logo-2.png"></img>
                </div> */}

                <div className="p-toolbar-group-left">
                    {/* <Button label="" icon="" className="no-text-button">
                        <img className="dots" src="/captionmax-dots.png"></img>
                    </Button> */}

                    <Button tooltip="Home" icon="far fa-home" onClick={e => this.homeButtonClicked()} />

                    <Button tooltip="Language" icon="far fa-language" />
                    <Button tooltip="Search" icon="far fa-search" />

                    <InstallPrompt />
                </div>

                {this.state.isTranscriber ?
                    <div className="p-toolbar-group-left">
                        <Button icon="far fa-address-book" tooltip="Event List" onClick={e => this.navigateToList()} />
                    </div>
                    : null
                }

                <div className="p-toolbar-group-right">
                    {this.state.autoScroll
                        ? <Button tooltip="Auto Scroll" onClick={e => this.toggleScrolling()} icon="fas fa-scroll" />
                        : <Button tooltip="Auto Scroll" onClick={e => this.toggleScrolling()} icon="fal fa-scroll" />}

                    {/* <Button onClick={e => this.toggleMenu()} tooltip="Show Menu" icon='far fa-menu' /> */}
                    <Button onClick={e => this.showSettingsClicked()} tooltip="Settings" icon='far fa-cog' />
                </div>
            </Toolbar>);
    }

    render(): React.ReactNode {
        return (<div className="AppToolbar">
            {this.state.showMenu
                ? <Toolbar>
                    <div className="p-toolbar-group-left">
                        <AppMenu />
                        <InstallPrompt />
                    </div>
                </Toolbar>
                : this.getRegularToolbar()}
        </div>);
    }
}