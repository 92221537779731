import React from 'react';
import { IEventMessage, ITranscriptionReaderSession } from '../code-base/shared/shared-interfaces';
import { ConversationSocketClient } from '../code-base/conversation-socket-client';
import { ConversationDataClient } from '../code-base/conversation-data-client';
import { store } from '../code-base/app-state';
import { createSetPropertyAction } from '../code-base/action-types';
import { Unsubscribe } from '@reduxjs/toolkit';
import './MessageList.scss';
import { EventFeedClient } from '../code-base/event-feed-client';
import { linesToParagraphs } from '../code-base/react.functions';

interface IMessageListState {
    /** Session being viewed. */
    session: ITranscriptionReaderSession;
}

export interface IMessageListProps {
    /** ID of the event to show messages for. */
    eventId: string;
}

export class MessageList extends React.Component<IMessageListProps, IMessageListState> {
    constructor(props: IMessageListProps) {
        super(props);

        // Get the current store, with the current state.
        let session = {
            eventInfo: { _id: props.eventId, name: 'Loading', code: '' },
            messages: new Array<IEventMessage>(),
        } as ITranscriptionReaderSession;

        // Set the state of the component.
        this.state = { session: session };

        // Subscribe to updates on the store.
        this.unsubscribeFromStore = store.subscribe(() => this.onStateChanged());
    }

    private unsubscribeFromStore: Unsubscribe;

    /** Called when the application state changes. */
    private onStateChanged(): void {
        if (!this.mounted) {
            return;
        }

        // Update the state with the application state.
        let state = store.getState();

        this.setState({ session: state.currentEvent });
    }

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    socketClient: ConversationSocketClient;

    dataClient: ConversationDataClient;

    feedClient: EventFeedClient;

    /** Flag indicating whether or not this component is unloaded, and should ignore any running promises. */
    private isDead: boolean = false;

    componentWillUnmount(): void {
        this.isDead = true;
        // Cleanup the feed.
        this.feedClient.closeFeed();
        this.unsubscribeFromStore();
    }

    componentDidMount() {
        this.mounted = true;

        this.socketClient = new ConversationSocketClient(this.props.eventId, 'transcriber');
        this.feedClient = new EventFeedClient(this.props.eventId);
        this.dataClient = new ConversationDataClient();

        // Update the event being watched.
        store.dispatch(createSetPropertyAction('currentEvent', this.state.session));

        // Update the event information for this component.
        this.dataClient.getConversationInfo(this.props.eventId).then(info => {
            // Exit if this component was unloaded.
            if (this.isDead)
                return;

            // Update the event info.
            store.dispatch(createSetPropertyAction('currentEvent.eventInfo', info));
        });

        // Update the message list.
        this.dataClient.getConversationEntries(this.props.eventId).then(messages => {
            // Exit if this component was unloaded.
            if (this.isDead)
                return;

            // Update the message list.
            store.dispatch(createSetPropertyAction('currentEvent.messages', messages));
        });
    }

    getMessageListing(message: IEventMessage) {
        return <div className="message-listing">
            {message.message}
        </div>
    }

    render(): React.ReactNode {
        // Setup the style from the configuration.
        let config = store.getState().appSettings.appearanceSettings;
        let style = {
            fontSize: config.fontSize,
            color: config.fontColor,
            backgroundColor: config.backgroundColor,
            font: config.font
        }

        return <div className="MessageList" style={style}>
            {this.state.session.messages.map(m => this.getMessageListing(m))}

            <div className="current-message">
                {linesToParagraphs(this.state.session.currentMessage)}
            </div>
        </div>
    }
}
