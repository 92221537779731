import { Socket } from "./socket-io/socket-io.typedefs";
import { S_START_LISTEN_SESSION, S_START_TRANSCRIBE_SESSION, S_END_TRANSCRIBE_SESSION, S_END_LISTEN_SESSION, S_MSG_TRANSCRIBER_TYPING, S_MSG_NEW_MESSAGE, S_MSG_UPDATE_MESSAGE, S_GET_ALL_LOGS, S_SUBSCRIBE_TO_LOGS, S_LOG_ITEM_ADDED, S_UNSUBSCRIBE_FROM_LOGS } from "./shared/socket-events";
import { getSocket } from "./socket-io/get-socket.functions";
import { Subject, Observable } from 'rxjs';
import { IEventMessage, ILogEntry } from "./shared/shared-interfaces";
import { convertDates } from "./shared/shared-code";
import { store } from "./app-state";
import { updateMessage, addMessage } from "./action-types";
import { SubscriptionHandler } from "./subscription-handler";

/** Enumerates the types of connections that can be made with the socket client. */
export type SocketConnectionTypes = 'listener' | 'transcriber' | 'logging';

/** Provides support for Socket.IO functionality with the server. */
export class ConversationSocketClient {
    constructor(readonly conversationId: string, readonly connectionType: SocketConnectionTypes) {
        // Set the socket we'll use for communications.
        this.socket = getSocket();

        // Initialize the socket.
        this.socket.open();

        if (connectionType != 'logging') {
            // Join the conversation on the server for events when they arrive.
            this.socket.emit(this.joinMessage, conversationId);

            this.subscriptions.subscribe(S_MSG_NEW_MESSAGE, (message: IEventMessage) => {
                // Convert any dates, if necessary.
                convertDates(message);
                // Tell observers about the new message.
                this.onNewMessageReceived(message);
            });
            this.subscriptions.subscribe(S_MSG_TRANSCRIBER_TYPING, () => { this.transcriberTyping.next(); });
            this.subscriptions.subscribe(S_MSG_UPDATE_MESSAGE, (message: IEventMessage) => {
                convertDates(message);
                this.onMessageUpdateReceived(message);
            });
        } else {
            // Logging:
            this.socket.emit(S_SUBSCRIBE_TO_LOGS);

            this.subscriptions.subscribe(S_LOG_ITEM_ADDED, (logItem: ILogEntry) => {
                convertDates(logItem);
                this.logReceived.next(logItem);
            });
        }
    }

    private subscriptions = new SubscriptionHandler(
        (event, handler) => this.socket.on(event, handler),
        (event, handler) => this.socket.off(event, handler)
    );

    /** Called when we're done with this client, and we need to release our resources. */
    close(): void {
        switch (this.connectionType) {
            case 'listener':
            case 'transcriber':
                // Leave the room this client is in.
                this.socket.emit(this.leaveMessage, this.conversationId);
                break;

            case 'logging':
                this.socket.emit(S_UNSUBSCRIBE_FROM_LOGS);
                break;

            default:
                throw new Error(`Unknown connection type ${this.connectionType}`);
        }


        // Cleanup our event handlers.
        this.subscriptions.unsubscribeAll();

        this.socket.disconnect();
        this.socket = null;

        this.messageSubject.complete();
        this.messageSubject = null;
        this.messageReceived$ = null;

        this.transcriberTyping.complete();
        this.transcriberTyping = null;
        this.transcriberTyping$ = null;
    }

    /** Called when an entry is updated on the server. */
    private onMessageUpdateReceived(message: IEventMessage): void {
        store.dispatch(updateMessage({ message }));
        this.messageUpdatedSubject.next(message);
    }

    /** Called when a messaged is updated on the server. */
    private messageUpdatedSubject = new Subject<IEventMessage>();
    private messageUpdated$ = this.messageUpdatedSubject.asObservable();

    /** Called when a new conversation entry is received from the server. */
    private onNewMessageReceived(newMessage: IEventMessage): void {
        store.dispatch(addMessage({ message: newMessage }));
        this.messageSubject.next(newMessage);
    }

    /** Event called when a new message is received for this channel. */
    private messageSubject = new Subject<IEventMessage>();
    /** Event called when a new message is received for this channel. */
    messageReceived$ = this.messageSubject.asObservable();

    /** Event called when a new log entry is created on the server. */
    private logReceived = new Subject<ILogEntry>();
    /** Event called when a new log entry is created on the server. */
    logReceived$ = this.logReceived.asObservable();

    /** Event called when the transcriber is typing on this channel. */
    private transcriberTyping = new Subject();
    /** Event called when the transcriber is typing on this channel. */
    transcriberTyping$ = this.transcriberTyping.asObservable();

    /** Called to inform the server the the transcriber has started typing. */
    onTranscriberTyping(): void {
        if (this.connectionType != 'transcriber')
            throw new Error(`Invalid connection type.`);

        // Inform the server.
        this.socket.emit(S_MSG_TRANSCRIBER_TYPING, this.conversationId);
    }

    /** Returns the message used to join a conversation when starting, based on the connection type. */
    private get joinMessage(): string {
        switch (this.connectionType) {
            case 'listener':
                return S_START_LISTEN_SESSION;

            case 'transcriber':
                return S_START_TRANSCRIBE_SESSION;

            default:
                throw new Error(`Unexpected connectionType: ${this.connectionType}`);
        }
    }

    /** Returns the message used to leave a conversation when finished, based on the connection type. */
    private get leaveMessage(): string {
        switch (this.connectionType) {
            case 'listener':
                return S_END_LISTEN_SESSION;

            case 'transcriber':
                return S_END_TRANSCRIBE_SESSION;

            default:
                throw new Error(`Unexpected connectionType: ${this.connectionType}`);
        }

    }

    /** Returns all log entries from the server. */
    getAllLogEntries(): Promise<Array<ILogEntry>> {
        return new Promise<Array<ILogEntry>>((res, rej) => {
            this.socket.emit(S_GET_ALL_LOGS, (entries: Array<ILogEntry>) => {
                convertDates(entries);
                res(entries);
            });
        });
    }

    socket: Socket;
}