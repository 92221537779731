import React, { Ref, RefObject } from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './AppMenu.scss';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { Button } from 'primereact/button';
import { changePage, createSetPropertyAction } from '../code-base/action-types';
import { appSlice } from '../code-base/reducers/app-state.reducer';

interface IAppMenuState { }
export interface IAppMenuProps { }

export class AppMenu extends React.Component<IAppMenuProps, IAppMenuState>{
    constructor(props: IAppMenuProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        this.menuRef = React.createRef();

        // Take care of showing/hiding the event list menu item.
        this.handleEventListMenuItem();
    }

    onStoreChanged(): void {
        // Take care of showing/hiding the event list menu item.
        this.handleEventListMenuItem();
    }

    menuRef: RefObject<Menu>;

    private unsubscribeFromStore: Unsubscribe;

    componentWillUnmount(): void {
        this.unsubscribeFromStore();
    }

    /** Toggles auto-scrolling, activated by the menu button. */
    toggleScrolling(): void {
        // Get the state of scrolling now.
        let appState = store.getState();
        let isScrolling = appState.appSettings.streamSettings.automaticScrolling;
        // Update the state to show the settings.
        store.dispatch(createSetPropertyAction('appSettings.streamSettings.automaticScrolling', !isScrolling));
    }

    /** Ensures the event list menu item is include or removed, based on whether or not a transcriber user is active. */
    private handleEventListMenuItem(): void {
        // Determine if we should show this.
        let showEventList = store.getState().isTranscriber;

        // Add or remove it as needed.
        if (showEventList) {
            if (!this.menuModel.includes(this.eventListMenuItem)) {
                this.menuModel.push(this.eventListMenuItem);
            }
        } else {
            if (this.menuModel.includes(this.eventListMenuItem)) {
                let id = this.menuModel.indexOf(this.eventListMenuItem);
                this.menuModel.splice(id, 1);
            }
        }
    }

    /** Navigates to the event list. */
    navigateToList(): void {
        // store.dispatch(changePage({ pageName: 'event-list' }));
        store.dispatch(appSlice.actions.changePage({ pageName: 'event-list' }));
    }

    /** Toggles whether or not the menu button is shown. */
    toggleMenu() {
        // Get the state of scrolling now.
        let appState = store.getState();
        let showMenu = appState.appSettings.appearanceSettings.showMenu;
        // Update the state to show the settings.
        store.dispatch(createSetPropertyAction('appSettings.appearanceSettings.showMenu', !showMenu));
    }

    eventListMenuItem: MenuItem = {
        label: 'Event List',
        icon: 'far fa-address-book',
        command: () => this.navigateToList()
    }

    menuModel: Array<MenuItem> = [
        {
            label: 'Home', icon: 'far fa-home', command: () => {
                // store.dispatch(changePage({ pageName: 'enter-code' }))
                store.dispatch(appSlice.actions.changePage({pageName: 'enter-code'}));
            },
        },
        { label: 'Language', icon: 'far fa-language' },
        { label: 'Search', icon: 'far fa-search' },
        { label: 'Auto-Scroll', icon: 'far fa-scroll', command: () => this.toggleScrolling() },
        { label: 'Settings', icon: 'far fa-cog', command: () => store.dispatch(createSetPropertyAction('showAppSettings', true)) },
        // { label: 'Toggle Menu', icon: 'far fa-menu', command: () => this.toggleMenu() }
    ];

    private get menu(): Menu {
        return this.menuRef.current;
    }

    render(): React.ReactNode {
        return (<div className="AppMenu">
            <Menu model={this.menuModel} popup={true} ref={this.menuRef} />
            <Button icon="pi pi-bars" onClick={(e: any) => this.menu.toggle(e)} />
        </div>);
    }
}