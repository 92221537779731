import { StringIndexable } from "./general.types";
import { IEventInfo, ITranscriptionReaderSession } from "./shared/shared-interfaces";
import { IAppSettings, createDefaultAppSettings } from "./app-settings";
import { BeforeInstallPromptEvent } from "./install-prompt.types";

/** Enumerates the pages that the app can be on. */
export type AppPageTypes = 'enter-code' | 'event-list' | 'event-view' | 'log-viewer';

/** Contains the state of the application. */
export interface IAppState extends StringIndexable {
    /** Set of available conversations. */
    eventList: IEventInfo[];

    /** The current conversation being edited/read by the user. */
    currentEvent: ITranscriptionReaderSession;

    /** Application settings. */
    appSettings: IAppSettings;

    /** Boolean value indicating whether or not the current user is a transcriber. */
    isTranscriber: boolean;

    /** When true, the app settings is to be shown. */
    showAppSettings: boolean;

    /** The page to show the user. */
    displayPage: AppPageTypes;

    /** Install event from the browser, allowing the user to install on their machine. */
    installEvent?: BeforeInstallPromptEvent;

    /** When the service worker is updated, this is the registration object for it. */
    updateServiceWorker?: ServiceWorkerRegistration;

    /** ID of the conversation to edit, when the user wishes to edit a conversation. */
    editConversationId?: string;
}

export function createDefaultAppState(): IAppState {
    return {
        eventList: [],
        currentEvent: null,
        appSettings: createDefaultAppSettings(),
        isTranscriber: false,
        showAppSettings: false,
        displayPage: 'enter-code',
        editConversationId: null,
    };
}