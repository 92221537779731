import { configureStore, Action, AnyAction } from '@reduxjs/toolkit';
import { appStateReducer } from '../code-base/reducers/app-state.reducer';
import { IAppState, createDefaultAppState } from './app-state.typedefs';
import { createDefaultAppSettings } from './app-settings';
import * as Redux from 'redux';

function thunk({ getState }: Redux.MiddlewareAPI<Redux.Dispatch, IAppState>) {
    return (next: Redux.Dispatch) => (action: AnyAction) => {
        // console.log(`Calling action: ${action.type} ${action.payload?.propertyPath}`);

        next(action);
        return action;
    }
}


export const store = configureStore<IAppState, AnyAction>({
    reducer: appStateReducer,
    preloadedState: createDefaultAppState(),
    middleware: [
        thunk,
    ]
});
