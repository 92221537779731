

/** Boolean value indicating whether or not a specified value is a date. */
export function isDate(target: any): boolean {
    if (typeof target !== 'string')
        return false;

    // Regular expression to detect a date.
    let pattern = /\d{2}\-\d{2}\-\d{1,2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/i

    // Return whether or not this is a date.
    return pattern.test(target);
}

/** Scans properties for string dates of a specified object, and converts them.
 *   This works recursively on properties of the target. */
export function convertDates(target: { [n: string]: any }): void {
    if (typeof target !== 'object' || target == null)
        return;

    if (Array.isArray(target)) {
        for (let i = 0; i < target.length; i++) {
            // Get the current value and its type.
            let val = target[i];
            let type = typeof val;

            // If this is a string, check for a date, and perform the conversion if necessary.
            if (type === 'string' && isDate(val)) {
                target[i] = new Date(val);
            }

            // If this is an object, convert the dates on it.
            if (type === 'object' && val != null)
                convertDates(val);
        }
    }
    else {
        for (let n in target) {
            // Get the value.
            let value = target[n];

            // Get the type.
            let type = typeof value;

            // If it's a string, test for a date, and convert if it is.
            if (type === 'string' && isDate(value)) {
                target[n] = new Date(value);
            }

            if (type === 'object' && value != null) {
                // Call this recursively on this object.
                convertDates(value);
            }
        }
    }
}