import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './InstallPrompt.scss';
import { createSetPropertyAction } from '../code-base/action-types';
import { BeforeInstallPromptEvent } from '../code-base/install-prompt.types';
import { Button } from 'primereact/button';
import { SubscriptionHandler } from '../code-base/subscription-handler';

interface IInstallPromptState {
    installEvent?: BeforeInstallPromptEvent;
    updateServiceWorker?: ServiceWorkerRegistration;
}
export interface IInstallPromptProps { }

export class InstallPrompt extends React.Component<IInstallPromptProps, IInstallPromptState>{
    constructor(props: IInstallPromptProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        let appState = store.getState();
        this.state = { installEvent: appState.installEvent, updateServiceWorker: appState.updateServiceWorker };
    }

    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }
        
        this.setState(p => {
            let state = store.getState();

            return { installEvent: state.installEvent, updateServiceWorker: state.updateServiceWorker };
        });
    }

    private unsubscribeFromStore: Unsubscribe;

    componentDidMount(): void {
        this.mounted = true;

        this.subscriptions.subscribe('beforeinstallprompt', (e: any) => {
            // Stop the default action from happening.
            e.preventDefault();

            // Set this in our state.
            store.dispatch(createSetPropertyAction('installEvent', e));
        });
    }

    private subscriptions = new SubscriptionHandler(
        (e, h) => window.addEventListener(e, h as any),
        (e, h) => window.removeEventListener(e, h as any));

    componentWillUnmount(): void {
        this.subscriptions.unsubscribeAll();
        this.unsubscribeFromStore();
    }

    /** Called when the user clicks the install button. */
    installClicked() {
        // Display the native prompt.
        this.state.installEvent.prompt();

        // Wait for the response.
        this.state.installEvent.userChoice.then(result => {
            console.log(`Install Outcome: ${result}`);
        });

        // Clear the state since this prompt was already used.
        store.dispatch(createSetPropertyAction('installEvent', null));

    }

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    get buttonState(): 'none' | 'update' | 'install' {
        if (this.state.updateServiceWorker) {
            return 'update';
        }

        return this.state.installEvent ? 'install' : 'none';
    }

    /** Returns the icon for the button to show. */
    get icon(): string {
        switch (this.buttonState) {
            case 'install':
                return 'fas fa-download';

            case 'update':
                return 'fas fa-sync';
        }
    }

    /** Called when the button is clicked, and determines the right action to perform. */
    executeButtonClicked(): void {
        switch (this.buttonState) {
            case 'install':
                this.installClicked();
                break;

            case 'update':
                const waitingWorker = this.state.updateServiceWorker.waiting;

                if (waitingWorker) {
                    waitingWorker.postMessage({ type: 'SKIP_WAITING' });

                    waitingWorker.addEventListener('statechange', (e: any) => {
                        if (e.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                }
                break;
        }
    }

    /** Returns the button tooltip, based on the state of the control. */
    get tooltip(): string {
        switch (this.buttonState) {
            case 'update':
                return 'A new version is available, click to update.';
            case 'install':
                return 'Install on your device!';

            default:
                return null;
        }
    }

    render(): React.ReactNode {
        // Determine the label, based on the size of the window and the state of the component.
        let label = this.buttonState === 'update' ? 'Update' : 'Install';

        // Remove the label if the view isn't wide enough.
        if (window.innerWidth < 500) {
            label = '';
        }

        if (this.buttonState !== 'none') {
            return (<div className="InstallPrompt">
                <Button icon={this.icon} label={label} tooltip={this.tooltip} onClick={() => this.executeButtonClicked()}></Button>
            </div>)
        } else {
            return null;
        }
    }
}