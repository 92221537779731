export interface EventSubscription {
    event: string;
    handler: Function;
}

export class SubscriptionHandler {
    constructor(
        /** Actual function to subscribe to a named event with a handler. */
        private readonly addSubscriptionImpl: (event: string, handler: Function) => void,
        /** Actual function to unsubscribe from a named event with a handler. */
        private readonly removeSubscriptionImpl: (event: string, handler: Function) => void,
    ) {

    }

    /** Set of subscriptions being managed. */
    subscriptions = new Array<EventSubscription>();

    /** Subscribes to a new event handler, and stores it for later to be removed. */
    subscribe(event: string, handler: Function): void {
        this.addSubscriptionImpl(event, handler);
        this.subscriptions.push({ event, handler });
    }

    /** Unsubscribes from a specific event. */
    unsubscribe(event: string): void {
        this.subscriptions.filter(s => s.event === event).forEach(s => {
            this.removeSubscriptionImpl(s.event, s.handler);
            // Don't worry, the .filter creates a new array.
            let id = this.subscriptions.indexOf(s);
            this.subscriptions.splice(id, 1);
        });
    }

    /** Unsubscribes from all event handlers. */
    unsubscribeAll(): void {
        this.subscriptions.forEach(s => {
            this.removeSubscriptionImpl(s.event, s.handler);
        });
        this.subscriptions = [];
    }
}


interface HandlerEntry {
    target: object;
    handler: SubscriptionHandler;
}

/** Provides SubscriptionHandler management for multiple objects. */
export class MultiSubscriptionHandler {
    constructor(
        /** Actual function to subscribe to a named event with a handler. */
        private readonly addSubscriptionImpl: (target: any, event: string, handler: Function) => void,
        /** Actual function to unsubscribe from a named event with a handler. */
        private readonly removeSubscriptionImpl: (target: any, event: string, handler: Function) => void,
    ) {

    }

    /** Set of subscription handlers for targets. */
    private handlers = new Array<HandlerEntry>();

    private getHandlerEntry(target: object, createIfNone: boolean): HandlerEntry {
        // Get the return value.
        let ret = this.handlers.find(h => h.target === target);

        /** Create a new entry if required. */
        if (ret == null && createIfNone) {
            ret = { target, handler: new SubscriptionHandler((e, h) => this.addSubscriptionImpl(target, e, h), (e, h) => this.removeSubscriptionImpl(target, e, h)) }
            this.handlers.push(ret);
        }

        // Return the result.
        return ret;
    }

    /** Subscribes a specfieid target object to a specified event.F */
    subscribe(target: object, event: string, handler: Function): void {
        // Get the entry.
        let entry = this.getHandlerEntry(target, true);

        // Add the handler.
        entry.handler.subscribe(event, handler);
    }

    /** Unsubscribes the target from a specified event. */
    unsubscribe(target: object, event: string): void {
        // Get the entry.
        let entry = this.getHandlerEntry(target, false);

        // Remove the subscription if it was found.
        if (entry) {
            entry.handler.unsubscribe(event);

            // If there are no more subscriptions, remove this entry.
            if (entry.handler.subscriptions.length < 1) {
                let id = this.handlers.indexOf(entry);
                this.handlers.splice(id, 1);
            }
        }
    }

    /** Unsubscribes a specified object from all events.  If no target is supplied
     *   then all subscriptions from all targets are removed. */
    unsubscribeAll(target?: object): void {
        if (target) {
            // Get the entry.
            let entry = this.getHandlerEntry(target, false);

            // Remove the subscriptions, if found.
            if (entry) {
                entry.handler.unsubscribeAll();
                // Remove it from the list.
                let id = this.handlers.indexOf(entry);
                this.handlers.splice(id, 1);
            }
        } else {
            this.handlers.forEach(h => {
                h.handler.unsubscribeAll();
            });
            
            this.handlers = [];
        }
    }
}