import { IAppState, createDefaultAppState } from "../app-state.typedefs";
import { StringIndexable, StringPayloadAction, StringAction } from "../general.types";
import { updateObjectPropertyPath, updateObjectProperty } from "../properties.functions";
import { AnyAction, createSlice, Action } from "@reduxjs/toolkit";
import { messagesReducer } from "./messages.reducer";
import { createDefaultAppSettings } from "../app-settings";
import { changePage, IChangePagePayload, IUpdateMessagePayload, IAddMessagePayload, ISetMessagesPayload, INavigateToEventPayload } from "../action-types";
import { eventReducer } from "./event.reducers";
import { Dispatch } from "react";
import { AppThunk } from "../app-thunk.typedefs";
import { ConversationDataClient } from "../conversation-data-client";
import { IEventInfo, ITranscriptionReaderSession } from "../shared/shared-interfaces";

const initialState: IAppState = createDefaultAppState();

/** TypeGuard for a "set-value" action. */
function isSetValueAction(action: StringIndexable & AnyAction): action is { type: 'set-value', payload: { propertyPath: string, newValue: any } } {
    return action.type === 'set-value' && action['payload']['propertyPath'] != null;
}

export const appSlice = createSlice(
    {
        initialState: createDefaultAppState(),
        name: 'app',
        reducers: {
            changePage(state, action: StringPayloadAction<IChangePagePayload>) {
                state.displayPage = action.payload.pageName;
            },
            updateMessage(state, action: StringPayloadAction<IUpdateMessagePayload>) {
                state.currentEvent.currentMessage = action.payload.message.message;
            },
            addMessage(state, action: StringPayloadAction<IAddMessagePayload>) {
                state.currentEvent.messages.push(action.payload.message);
            },
            setMessages(state, action: StringPayloadAction<ISetMessagesPayload>) {
                state.currentEvent.messages = action.payload.messages;
            },
            updateCurrentEventInfo(state, action: StringPayloadAction<{ eventInfo: IEventInfo }>) {
                if (state.currentEvent == null)
                    state.currentEvent = { eventInfo: action.payload.eventInfo, messages: [], currentMessage: '' } as ITranscriptionReaderSession
                else
                    state.currentEvent.eventInfo = action.payload.eventInfo;
            }
        }
    }
);

export const appStateReducer = function (state: IAppState = initialState, action: AnyAction): IAppState {
    // Handle the set-value action.
    if (isSetValueAction(action)) {
        // Use the default update method.
        return updateObjectPropertyPath(state, action.payload.propertyPath, action.payload.newValue);
    }

    // Get the action type as a string.
    let type: string = action.type;

    // Check if this is a messages item.
    let newState = messagesReducer(state, action);

    // Check if the event reducer handles this.
    newState = eventReducer(newState, action);

    newState = appSlice.reducer(newState, action);

    return newState;
}

export function navigateToEvent(eventId: string): AppThunk {
    return next => (action: StringPayloadAction<INavigateToEventPayload>) => {
        let client = new ConversationDataClient();

        client.getConversationInfo(eventId).then(event => {
            appSlice.actions.updateCurrentEventInfo({ eventInfo: event });
            appSlice.actions.changePage({ pageName: 'event-view' });
        });
    }
}
