import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './LogViewier.scss';
import { ILogEntry, ILogMessageEntry, ILogObjectEntry } from '../code-base/shared/shared-interfaces';
import { ConversationSocketClient } from '../code-base/conversation-socket-client';
import { SubscriptionHandler } from '../code-base/subscription-handler';
import { Subscription } from 'rxjs';
import { stringify } from 'querystring';

interface ILogViewierState {
    logs: Array<ILogEntry>;
}
export interface ILogViewierProps { }

function isMessageLog(target: any): target is ILogMessageEntry {
    return typeof target.message === 'string';
}

function isObjectLog(target: any): target is ILogObjectEntry {
    return target.message == null && typeof target.obj === 'object';
}

export class LogViewier extends React.Component<ILogViewierProps, ILogViewierState>{
    constructor(props: ILogViewierProps) {
        super(props);

        this.state = { logs: [] };

        // Setup the client.
        this.client = new ConversationSocketClient(null, 'logging');

        this.subscriptions.push(this.client.logReceived$.subscribe(entry => {
            this.setState(prevState => {
                let newMessages = prevState.logs.slice();
                newMessages.splice(0, 0, entry);
                return { ...prevState, logs: newMessages };
            });
        }));
    }

    /** RSJX Subscriptions to detach from when we close. */
    private subscriptions = new Array<Subscription>();

    /** Provides log messages from the server. */
    private client: ConversationSocketClient;

    componentDidMount(): void {
        // Get the log entries.
        this.client.getAllLogEntries().then(entries => {
            this.setState(prevState => ({ ...prevState, logs: entries }));
        });
    }

    componentWillUnmount(): void {
        // Unsubscribe from events.
        this.subscriptions.forEach(s => s.unsubscribe());

        // Kill the client.F
        this.client.close();
    }

    private getPropertiesList(target: { [n: string]: any }, id: string): React.ReactNode {
        let props = new Array<{ name: string, val: string, type: string }>();

        for (let n in target) {
            let val = target[n];
            props.push({ name: n, val: val, type: typeof val });
        }

        return <React.Fragment>
            {props.map((item, index) => {
                let valueClass = 'value';
                let propClass = 'property';
                if (index === props.length - 1) {
                    valueClass += ' border-row';
                    propClass += ' border-row';
                }

                return <div key={id + '_' + item.name} style={({ gridColumnStart: 1, gridColumnEnd: 3 })} className="object-property">
                    <div className={propClass}>{item.name}</div>
                    <div className={valueClass}>{item.val == null ? 'null' : item.val.toString()}</div>
                </div>
            })}
        </React.Fragment>
    }

    /** Creates the DOM for a specified log entry. */
    createLogEntry(entry: ILogEntry): React.ReactNode {
        if (isMessageLog(entry)) {
            return <React.Fragment key={entry._id}>
                <div className="header-row" style={({ gridColumn: 1 })}>{entry.time?.toLocaleTimeString()}</div>
                <div className="header-row" style={({ gridColumn: 2 })}>{entry.source}</div>
                <div style={({ gridColumnStart: 1, gridColumnEnd: 3 })} className="border-row">{entry.message}</div>
            </React.Fragment>
        } else {
            return <React.Fragment key={entry._id}>
                <div className="header-row" style={({ gridColumn: 1 })}>{entry.time?.toLocaleTimeString()}</div>
                <div className="header-row" style={({ gridColumn: 2 })}>{entry.source}</div>
                {this.getPropertiesList(entry.obj, entry._id)}
            </React.Fragment>
        }
    }

    render(): React.ReactNode {
        return <div className="LogViewer">
            <div className="log-entries">
                {this.state.logs.map(l => this.createLogEntry(l))}
            </div>
        </div>
    }
}
