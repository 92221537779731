

/** General application settings. */
export interface IAppSettings {
    streamSettings: IStreamSettings;

    appearanceSettings: IAppearanceSettings;
}

export interface IStreamSettings {
    showTime: boolean;
    showLineNumbers: boolean;
    showCaret: boolean;
    automaticScrolling: boolean;
}

export interface IAppearanceSettings {
    font: string;
    fontSize: string;
    fontColor: string;
    backgroundColor: string;
    showMenu: boolean;
}

export function createDefaultAppSettings(): IAppSettings {
    let ret: IAppSettings = {
        appearanceSettings: {
            backgroundColor: '#ffffff',
            fontColor: '#000000',
            font: 'Arial',
            fontSize: '10pt',
            showMenu: false
        },

        streamSettings: {
            automaticScrolling: true,
            showCaret: false,
            showLineNumbers: false,
            showTime: true
        }
    }

    return ret;
}