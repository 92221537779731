import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './App/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './code-base/app-state';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ServiceWorkerController } from './service-worker-controller';


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(new ServiceWorkerController());
// serviceWorker.unregister();
