import React from 'react';
import './EventList.scss';
import { ConversationDataClient } from '../code-base/conversation-data-client';
import { store } from '../code-base/app-state';
import { createSetPropertyAction, navigateToEvent } from '../code-base/action-types';
import { Unsubscribe } from '@reduxjs/toolkit';
import { IEventInfo } from '../code-base/shared/shared-interfaces';

interface IEventListState {
    eventList: IEventInfo[];
}
export interface IEventListProps { }

export class EventList extends React.Component<IEventListProps, IEventListState>{
    constructor(props: IEventListProps) {
        super(props);

        this.dataClient = new ConversationDataClient();

        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        this.state = { eventList: store.getState().eventList ?? [] };
    }

    private unsubscribeFromStore: Unsubscribe;

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }

        this.setState({ eventList: store.getState().eventList ?? [] });
    }

    componentWillUnmount(): void {
        this.isDead = true;
        this.unsubscribeFromStore();
    }

    /** Flag indicating whether or not this component is unloaded, and should ignore any running promises. */
    private isDead = false;

    /** Data client to communicate with the server on. */
    dataClient: ConversationDataClient;

    componentDidMount(): void {
        this.mounted = true;

        // Get the list from the server.
        this.dataClient.getConversationList().then(newList => {
            // Exit if this component is unloaded.
            if (this.isDead)
                return;

            // Update the application state with this list.
            store.dispatch(createSetPropertyAction('eventList', newList));
        });
    }

    /** Called when the user clicks an edit button. */
    private editItemClicked(id: string): void {
        store.dispatch(createSetPropertyAction('editConversationId', id));
    }

    private getEventItem(event: IEventInfo) {
        return <React.Fragment key={event._id}>
            <div className="name">{event.name}</div>

            <a href="#" className="code" onClick={e => this.navigateToEvent(event._id)}>
                <div>{event.code}</div>
            </a>

            <a href="#" className="edit" onClick={e => this.editItemClicked(event._id)}>
                Edit
            </a>
        </React.Fragment>
    }

    /** Navigates to the viewer for the event with a specified ID. */
    private navigateToEvent(id: string): void {
        // Create the object to place in the state for navigation.
        store.dispatch(navigateToEvent({ _id: id }));

        // Set the event on the state.
        this.dataClient.getConversationInfo(id).then(result => {
            if (result) {
                store.dispatch(navigateToEvent({ eventInfo: result }));
            }
        });
    }

    render(): React.ReactNode {
        if (this.state == null) {
            return null;
        }

        return (
            <div className="EventList">
                <div className="name">Name</div>
                <div className="code">Event Code</div>
                <div className="edit">Edit</div>
                {this.state.eventList.map(s => this.getEventItem(s))}
            </div>
        );
    }
}