import { BASE_CONVERSATIONS_ENDPOINT, EP_CREATE_CONVERSATION, EP_GET_CONVERSATIONS, EP_GET_CONVERSATION_ENTRIES, EP_GET_CONVERSATION_INFO, EP_ADD_MESSAGE_TO_CONVERSATION, EP_GET_EVENT_ID_FROM_CODE, EP_GET_FULL_EVENT_INFO, EP_UPDATE_EVENT } from "./shared/endpoints";
import { IEventInfo, IEventMessage } from "./shared/shared-interfaces";
import { convertDates } from "./shared/shared-code";


/** Provides communication services with the conversation server. */
export class ConversationDataClient {

    private performRequest<T>(url: string, parseResponse: boolean, data: object | null): Promise<T> {
        // Construct the full endpoint with the base tacked on.
        url = ConversationDataClient.constructEndpoint(url);

        // Request to send to the server.
        let req = new XMLHttpRequest();

        // Reformat the data to a string, if it's a string.
        let payload: string | null = null;
        if (data != null) {
            payload = JSON.stringify(data);
        }

        // Return value with the request's parameters filled in.
        let res = new Promise<T>((res, rej) => {
            req.onload = function (e) {
                let result = this.response;

                // If we don't have any response, then exit with a null result.
                if (result == null)
                    return res();

                // If this is a string, then we need to parse and return it.
                if (typeof result == 'string' && parseResponse) {
                    // Get the JSON object returned.
                    let jsonObject = JSON.parse(result);

                    // Convert the dates to date values.
                    convertDates(jsonObject);

                    // The value should be in the result property.  Return that.
                    return res(jsonObject['result']);
                }

                // Just return the result - don't know what else to do with it.
                return res(result);
            };

            // Handle errors.
            req.onerror = function (e) {
                rej('Error: ' + this.statusText);
            }

            // Perform the request.
            req.open('POST', url);
            if (payload != null) {
                req.send(payload);
            } else {
                req.send();
            }
        });

        // Return the promise.
        return res;
    }

    /** Returns the combination of the baseEndpoint with a specified endpoint to use for registration. */
    private static constructEndpoint(endpoint: string): string {
        // Ensure the base endpoint ends with a slash.
        let baseEndpoint = BASE_CONVERSATIONS_ENDPOINT;
        if (!baseEndpoint.endsWith('/'))
            baseEndpoint += '/';

        // Ensure the provided endpoint does not start with a slash.
        if (endpoint.startsWith('/'))
            endpoint = endpoint.substr(1);

        // Return the combination.
        return baseEndpoint + endpoint;
    }

    /** Returns a list of conversations that can be shown/subscribed to. */
    getConversationList(): Promise<Array<IEventInfo>> {
        return this.performRequest<Array<IEventInfo>>(EP_GET_CONVERSATIONS, true, null);
    }

    /** Creates a new conversation on the server, and returns the info for it. */
    async createConversation(name: string, code: string, content: string): Promise<IEventInfo> {
        // Validate the inputs.
        if (name == null || name.trim() === '')
            throw new Error('Invalid name.');

        if (code == null || code.trim() === '')
            throw new Error(`Invalid Code`);

        // Perform the request.
        let res = await this.performRequest<string>(EP_CREATE_CONVERSATION, true, { name: name, code: code, content: content });

        // Create a new conversation with this info, and return it.
        return { name: name, _id: res, code: code };
    }

    /** Creates a new message for a specified conversation on the server. */
    async addMessageToConversation(entry: Partial<IEventMessage>): Promise<IEventMessage> {
        // Perform the request.
        let res = await this.performRequest<string>(EP_ADD_MESSAGE_TO_CONVERSATION, true, entry);

        // Set the ID on the message, and return it.
        entry._id = res;
        return <IEventMessage>entry;
    }

    /** Returns all conversation entries for a specified conversation ID. */
    getConversationEntries(conversationId: string): Promise<Array<IEventMessage>> {
        return this.performRequest<Array<IEventMessage>>(EP_GET_CONVERSATION_ENTRIES, true, { conversationId: conversationId });
    }

    /** Returns the conversation information for a conversation specified by its ID. */
    getConversationInfo(conversationId: string): Promise<IEventInfo> {
        return this.performRequest<IEventInfo>(EP_GET_CONVERSATION_INFO, true, { _id: conversationId });
    }

    /** Given an event code, returns the ID for the event. */
    async getEventIdFromCode(code: string): Promise<IEventInfo | null> {
        // Validate the code.
        if (code == null || code.trim() === '')
            throw new Error(`Invalid code.`);

        // Perform the request, and return the result.
        return this.performRequest(EP_GET_EVENT_ID_FROM_CODE, true, { code: code });
    }

    /** Returns the full event, including content, for a specified event. */
    async getFullEvent(id: string): Promise<IEventInfo | null> {
        return this.performRequest(EP_GET_FULL_EVENT_INFO, true, { _id: id });
    }

    /** Updates a specified event in the database, on the server. */
    async updateEvent(event: IEventInfo): Promise<void> {
        this.performRequest(EP_UPDATE_EVENT, true, { event });
    }
}