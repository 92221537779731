import { IEventMessage, IEventInfo } from "../shared/shared-interfaces";
import { StringAction, isStringAction } from "../general.types";
import { updateMessage, IUpdateMessagePayload, addMessage, IAddMessagePayload, setMessages, ISetMessagesPayload } from "../action-types";
import { IAppState } from "../app-state.typedefs";
import { updateObjectPropertyPath, updateObjectProperty } from "../properties.functions";
import { AnyAction } from "@reduxjs/toolkit";

export const messagesReducer = function (state: IAppState, action: AnyAction): IAppState {
    return state;
    if (isStringAction(action)) {
        switch (action.type) {
            case updateMessage.toString():
                {
                    // Get the payload as the new message.
                    let newMessage = (<IUpdateMessagePayload>action.payload).message;

                    // Find the message in question.
                    let index = state.currentEvent.messages.findIndex(m => m._id === newMessage._id);

                    // If not found, just add the message.
                    if (index < 0) {
                        let messages = state.currentEvent.messages.slice();
                        messages.push(newMessage);

                        // Update this in the state.
                        return updateObjectPropertyPath(state, 'currentEvent.messages', messages);

                    } else {
                        // Replace this item.
                        return updateObjectPropertyPath(state, `currentEvent.messages.[${index}]`, newMessage);
                    }
                }

            case addMessage.toString():
                {
                    // Get the message from the payload.
                    let newMessage = (<IAddMessagePayload>action.payload).message;

                    // Get a copy of the messages.
                    let messages = state.currentEvent.messages.slice();
                    messages.push(newMessage);

                    // Update this in the state.
                    return updateObjectPropertyPath(state, 'currentEvent.messages', messages);
                }
            case setMessages.toString():
                {
                    // Get the messages.
                    let messages = (<ISetMessagesPayload>action.payload).messages;
                    return updateObjectPropertyPath(state, 'currentEvent.messages', messages);
                }
        }
    }

    return state;
}
