import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './AppSettings.scss';
import { IAppSettings } from '../code-base/app-settings';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { createSetPropertyAction } from '../code-base/action-types';
import { ColorPicker } from 'primereact/colorpicker';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { IAppState } from '../code-base/app-state.typedefs';

const getFontSize = (state: IAppState) => {
    let val = state.appSettings?.appearanceSettings?.fontSize;
    let rVal = parseInt(val);
    if (isNaN(rVal)) {
        return '';
    }

    return rVal + '';
}

interface IAppSettingsState {
    /** The app settings to display. */
    settings: IAppSettings;

    /** Gets or sets the font size to show in the UI. */
    fontSize: string;

    /** The tab that's currently selected in the tab view. */
    currentTab: number;
}
export interface IAppSettingsProps { }

export class AppSettings extends React.Component<IAppSettingsProps, IAppSettingsState>{
    constructor(props: IAppSettingsProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        let state = store.getState();
        this.state = { settings: state.appSettings, currentTab: 0, fontSize: getFontSize(state) };
    }

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    componentDidMount(): void {
        this.mounted = true;
    }

    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }

        let state = store.getState();
        this.setState(p => ({ ...p, settings: state.appSettings }));
    }

    onFontSizeChanged(val: string): void {
        let newVal = parseInt(val);

        if (!isNaN(newVal)) {
            this.setState(p => ({ ...p, fontSize: newVal + '' }));
            store.dispatch(createSetPropertyAction('appSettings.appearanceSettings.fontSize', newVal + 'pt'));
        } else {
            this.setState(p => ({ ...p, fontSize: val + '' }));
        }
    }

    private unsubscribeFromStore: Unsubscribe;

    componentWillUnmount(): void {
        this.unsubscribeFromStore();
    }

    onSettingChanged(property: string, newValue: any): void {
        // Update the state with the appropriate setting.
        store.dispatch(createSetPropertyAction('appSettings.' + property, newValue));
    }

    /** Returns the first column of the settings. */
    getColumn1(): React.ReactNode {
        const settings = this.state.settings;

        return (<div className="settings-column">
            <label className="description">Automatic Scrolling</label>
            <div className="control">
                <Checkbox checked={settings.streamSettings.automaticScrolling} onChange={e => this.onSettingChanged('streamSettings.automaticScrolling', e.checked)}></Checkbox>
            </div>

            <label className="description">Show Time</label>
            <div className="control">
                <Checkbox checked={settings.streamSettings.showTime} onChange={e => this.onSettingChanged('streamSettings.showTime', e.checked)}></Checkbox>
            </div>

            <label className="description">Show Line Numbers</label>
            <div className="control">
                <Checkbox checked={settings.streamSettings.showLineNumbers} onChange={e => this.onSettingChanged('streamSettings.showLineNumbers', e.checked)}></Checkbox>
            </div>

            <label className="description">Show Caret</label>
            <div className="control">
                <Checkbox checked={settings.streamSettings.showCaret} onChange={e => this.onSettingChanged('streamSettings.showCaret', e.checked)}></Checkbox>
            </div>
        </div>
        );
    }

    /** Returns the second column of the settings. */
    getColumn2(): React.ReactNode {
        const settings = this.state.settings;

        const body = document.body;

        return (<div className="settings-column">
            <label className="description">Font</label>
            <div className="control">
                <InputText value={settings.appearanceSettings.font} onChange={e => this.onSettingChanged('appearanceSettings.font', (e.target as any).value)} />
            </div>

            <label className="description">Font Size</label>
            <div className="control inline-grid">
                <InputText value={this.state.fontSize + ''} onChange={e => this.onFontSizeChanged((e.target as any).value)} />
                <div>PT</div>
            </div>

            <label className="description">Show Menu</label>
            <div className="control">
                <Checkbox checked={settings.appearanceSettings.showMenu} onChange={e => this.onSettingChanged('appearanceSettings.showMenu', e.checked)}></Checkbox>
            </div>

            <label className="description">Font Color</label>
            <div className="control">
                {/* <InputText value={settings.appearanceSettings.fontColor} onChange={e => this.onSettingChanged('appearanceSettings.fontColor', (e.target as any).value)} /> */}
                <ColorPicker appendTo={body} value={settings.appearanceSettings.fontColor.substr(1)} onChange={e => this.onSettingChanged('appearanceSettings.fontColor', '#' + e.value)} />
            </div>

            <label className="description">Background Color</label>
            <div className="control">
                {/* <InputText value={settings.appearanceSettings.backgroundColor} onChange={e => this.onSettingChanged('appearanceSettings.backgroundColor', (e.target as any).value)} /> */}
                <ColorPicker appendTo={body} value={settings.appearanceSettings.backgroundColor.substr(1)} onChange={e => this.onSettingChanged('appearanceSettings.backgroundColor', '#' + e.value)} />
            </div>
        </div>
        )
    }

    /** Sets the tab in the tab view. */
    changeTab(tabNumber: number): void {
        this.setState({ ...this.state, currentTab: tabNumber });
    }

    /** Called to close the dialog. */
    closeDialog(): void {
        store.dispatch(createSetPropertyAction('showAppSettings', false));
    }

    render(): React.ReactNode {
        let appState = store.getState();

        return (
            <div className="AppSettings">
                <Dialog onHide={this.closeDialog} visible={appState.showAppSettings}>
                    <TabView activeIndex={this.state.currentTab} onTabChange={e => this.changeTab(e.index)} >
                        <TabPanel header="Stream">
                            {this.getColumn1()}
                        </TabPanel>
                        <TabPanel header="Appearance">
                            {this.getColumn2()}
                        </TabPanel>
                    </TabView>

                    <div>
                        <Button onClick={e => this.closeDialog()} label="OK" />
                    </div>
                </Dialog>
            </div>
        )
    }
}