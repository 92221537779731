import { Socket } from './socket-io/socket-io.typedefs';
import { getSocket } from './socket-io/get-socket.functions';
import {
    S_SUBSCRIBE_TO_FEED, S_MSG_CLEAR_MESSAGE, S_MSG_UPDATE_FEED, S_UNSUBSCRIBE_FROM_FEED,
    S_FULL_FEED, S_SUBSCRIPTION_READY, S_PING, S_GET_FULL_FEED
} from './shared/socket-events';
import { store } from './app-state';
import { createSetPropertyAction } from './action-types';

/** Client class that will subscribe to event feeds. */
export class EventFeedClient {
    constructor(
        /** ID of the event this feed is subscribed to. */
        readonly eventId: string) {

        // Setup the socket.
        this.socket = getSocket();
        this.socket.open();
        this.initializeSocket();

        // Join the event.
        this.socket.emit(S_SUBSCRIBE_TO_FEED, eventId);

        // Create a timer to fully update the message every now and then.
        this.fullRefreshTimer = setInterval(() => {
            this.socket.emit(S_GET_FULL_FEED, (res: string) => {
                store.dispatch(createSetPropertyAction('currentEvent.currentMessage', res));
            });
        }, 6000);
    }

    private static nextId: number = 1;

    readonly id = EventFeedClient.nextId++;

    private fullRefreshTimer: any;

    readonly socket: Socket;

    /** Called when this client feed is complete. */
    closeFeed(): void {
        // Cleanup the event handlers.
        this.detachFromSocket();
        clearInterval(this.fullRefreshTimer);
        this.socket.emit(S_UNSUBSCRIBE_FROM_FEED, this.eventId);
    }

    /** Initializes events on the socket. */
    private initializeSocket(): void {
        this.subscribeToEvent(S_SUBSCRIPTION_READY, (fullFeed: string) => {
            // Reset the value.
            store.dispatch(createSetPropertyAction('currentEvent.currentMessage', fullFeed));
        });

        this.subscribeToEvent(S_PING, (id: number) => {
            // Send a response.
            this.socket.emit(S_PING, id);
        });

        this.subscribeToEvent(S_FULL_FEED, (fullFeed: string) => {
            // Reset the value.
            store.dispatch(createSetPropertyAction('currentEvent.currentMessage', fullFeed));
        });

        this.subscribeToEvent(S_MSG_CLEAR_MESSAGE, () => {
            // Clear the current message.
            store.dispatch(createSetPropertyAction('currentEvent.currentMessage', ''))
        });

        this.subscribeToEvent(S_MSG_UPDATE_FEED, (message: string) => {
            // Get the current state.
            let state = store.getState();

            // Get the current message.
            let currentMessage = state.currentEvent?.currentMessage ?? '';

            // Add this to the message.
            currentMessage += message;

            // Update the state.
            store.dispatch(createSetPropertyAction('currentEvent.currentMessage', currentMessage));
        });
    }

    private subscriptions = new Array<ISubscription>();

    private detachFromSocket(): void {
        this.subscriptions.forEach(s => {
            this.socket.off(s.event, s.handler);
        });
    }

    /** Adds an event handler to a specified message, and stores it for unsubscribing later. */
    private subscribeToEvent(event: string, handler: Function): void {
        // Create the subscription.
        this.subscriptions.push({ event, handler });

        // Add the subscription.
        this.socket.on(event, handler);
    }

}

interface ISubscription {
    event: string;
    handler: Function;
}