import { store } from "./code-base/app-state";
import { createSetPropertyAction } from "./code-base/action-types";


export class ServiceWorkerController {
    constructor() {
        console.log('SW Controller Created');
    }
    
    onSuccess(): void {
        console.log(`SW Installed`);
    }

    onUpdate(registration: ServiceWorkerRegistration): void {
        console.log(`SW Updated`);
        store.dispatch(createSetPropertyAction('updateServiceWorker', registration));
    }
}