import React from 'react';
import './App.scss';
import { EventList } from '../EventList/EventList';
import { CreateEvent } from '../CreateEvent/CreateEvent';
import { Card } from 'primereact/card';

import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import { createSetPropertyAction } from '../code-base/action-types';
import { AppToolbar } from '../AppToolbar/AppToolbar';
import { AppSettings } from '../AppSettings/AppSettings';
import { AppPageTypes } from '../code-base/app-state.typedefs';
import { EnterEvent } from '../EnterEvent/EnterEvent';
import { EventView } from '../EventView/EventView';
import { IEventMessage } from '../code-base/shared/shared-interfaces';
import { InstallPrompt } from '../InstallPrompt/InstallPrompt';
import { LogViewier } from '../LogViewier/LogViewier';


interface IAppState {
  /** A boolean value indicating whether or not the user is a transcriber. */
  isTranscriber: boolean;

  /** Current page to display to the user. */
  displayPage: AppPageTypes;

}
export interface IAppProps { }

export class App extends React.Component<IAppProps, IAppState>{
  constructor(props: IAppProps) {
    super(props);

    let appState = store.getState();

    this.state = { isTranscriber: appState.isTranscriber, displayPage: appState.displayPage };
    this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());
  }

  onKeyPress(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      if (e.key === ';') {
        // Toggle the transcriber state.
        store.dispatch(createSetPropertyAction('isTranscriber', !store.getState().isTranscriber));
      }

      if (e.key === '\'') {
        store.dispatch(createSetPropertyAction('displayPage', 'log-viewer'));
      }
    }
  }

  /** Boolean value indicating whether or not this component has mounted. */
  private mounted = false;

  private lastMessageList: Array<IEventMessage>;

  private lastCurrentMessage: string;

  private contentRef = React.createRef<HTMLDivElement>();

  onStoreChanged(): void {
    if (!this.mounted) {
      return;
    }

    let appState = store.getState();
    this.setState({ isTranscriber: appState.isTranscriber, displayPage: appState.displayPage });
  }

  componentDidUpdate(): void {
    let appState = store.getState();

    // Scroll tot he bottom of the view if required.
    if (appState.appSettings.streamSettings.automaticScrolling) {
      if (appState.currentEvent?.messages != this.lastMessageList ||
        appState.currentEvent?.currentMessage != this.lastCurrentMessage) {
        // Wait a brief time for everything to update.
        setTimeout(() => {
          this.contentRef.current.scroll({ top: this.contentRef.current.scrollHeight, behavior: 'smooth' });
        });
      }
    }
  }

  private unsubscribeFromStore: Unsubscribe;

  componentWillUnmount(): void {
    window.removeEventListener('keyup', this.onKeyPress);
    this.unsubscribeFromStore();
  }

  componentDidMount(): void {
    this.mounted = true;
    window.addEventListener('keyup', this.onKeyPress);
  }

  render(): React.ReactNode {
    return (
      <div className="App">
        <div className="app-container">
          <div id="app-top">
            <AppToolbar />
            <AppSettings />
          </div>
          <div id="app-content" ref={this.contentRef}>
            {this.state.displayPage === 'event-list' ?
              <React.Fragment>
                <Card>
                  <EventList />
                </Card>
                <Card>
                  <CreateEvent />
                </Card>
              </React.Fragment>
              : null
            }

            {this.state.displayPage === 'event-view' ?
              <Card>
                <EventView />
              </Card>
              : null}

            {this.state.displayPage === 'enter-code' ?
              <Card>
                <EnterEvent />
              </Card> : null}

            {this.state.displayPage === 'log-viewer' ?
              <Card>
                <LogViewier />
              </Card> : null}

          </div>

          <div id="app-bottom" className="footer">
            <div>
              <a href="https://captionmax.com/" target="_blank">
                <img id="footer-logo" src="captionmax-logo.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

