
export const BASE_CONVERSATIONS_ENDPOINT = 'api/conversations';

export const EP_GET_CONVERSATIONS = 'get-conversations';
export const EP_CREATE_CONVERSATION = 'create-conversation';
export const EP_GET_CONVERSATION_ENTRIES = 'get-conversation-entries';
export const EP_ADD_MESSAGE_TO_CONVERSATION = 'add-message-to-conversation';
export const EP_GET_CONVERSATION_INFO = 'get-conversation-info';
export const EP_GET_EVENT_ID_FROM_CODE = 'get-event-id-from-code';

export const EP_GET_FULL_EVENT_INFO = 'get-full-event-info';
export const EP_UPDATE_EVENT = 'update-event';