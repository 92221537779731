import React from 'react';

/** Returns a set of P elements for each line in a specified content string. */
export function linesToParagraphs(content: string, className?: string, style?: object): React.ReactNode {
    // If this is empty, then return an empty div.
    if (content == null || content.trim() === '') {
        return <div></div>
    }
    
    let lines = content.split(/\r?\n/);
    return (<React.Fragment>
        {lines.map(l => <p className={className} style={style}>{l}</p>)}
    </React.Fragment>)
}