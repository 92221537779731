import { AnyAction } from "@reduxjs/toolkit";

/** An object that can be used in a for-in loop, with string keys. */
export type StringIndexable = { [n: string]: any };

/** An action that has a type of string, and any payload value. */
export type StringAction = { type: string, payload: any };

/** An action that has a type of string, and specifically typed payload. */
export type StringPayloadAction<T> = { type: string, payload: T };

/** TypeGuard for AnyAction. */
export function isAnyAction(target: StringIndexable): target is AnyAction {
    return typeof target['type'] != null;
}

/** TypeGuard for StringAction. */
export function isStringAction(target: StringIndexable): target is StringAction {
    return typeof target['type'] === 'string';
}