

/** Returns a shallow copy of a specified object, with a specified property changed to a new specified value.*/
export function updateObjectProperty<T, K extends keyof T>(state: T, prop: K, newValue: T[K]): T {
    return { ...state, [prop]: newValue };
}

export function updateObjectPropertyPath<T extends { [n: string]: any }>(state: T, propertyPath: string, newValue: any): T {
    // Determine which delimiter to use for splitting.
    let delimiter = '/';
    if (propertyPath.indexOf('/') < 0) {
        // Try the period.
        if (propertyPath.indexOf('.') < 0) {
            // We can just use the updateObjectProperty method instead.
            return updateObjectProperty(state, propertyPath, newValue);
        }

        // Use the period, because we found one.
        delimiter = '.';
    }

    // Parse the property path.
    let props = propertyPath.split(delimiter);

    // Get the values for these, save the last one, since that's changing.
    let vals = new Array<any>();
    // Get the current item being processed, and start it with the input state.
    let current: { [n: string]: any } = state;
    // Add this as the first value in the values list.
    vals.push(current);

    // Fill in all of the values for each property in the property path, except the last one.
    //  That one will be changed to the new value, and we have nothing to do with it.
    for (let i = 0; i < props.length - 1; i++) {
        current = current[props[i]];
        vals.push(current);
    }

    // Reassemble the values.  newValue is the reconstructed property value.
    //  Assemble everything in reverse order of their property list.
    let nextValue: any = newValue;
    for (let i = vals.length - 1; i >= 0; i--) {

        // Account for whether or not this is an array.
        let arrayTest = /\[(\d+)\]/.exec(props[i]);
        if (arrayTest != null) {
            // Get the index for this array property.
            let index = parseInt(arrayTest[1]);

            // Ensure this object is an array.
            if (!Array.isArray(vals[i]))
                throw new Error(`Value is not an array.`);

            // Copy the array to the new value.
            let arrayValue = <Array<any>>vals[i];
            let arrayCopy = arrayValue.slice();

            // Update the indexed item.
            arrayCopy.splice(i, nextValue);

            // Update the next value for the next iteration.
            nextValue = arrayCopy;

        } else {
            // Set the next value to set on the next object to be a copy of the current
            //  value, with its property updated to the last "next value".
            nextValue = { ...vals[i], [props[i]]: nextValue };
        }
    }

    return nextValue;
}