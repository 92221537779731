import { IAppState } from "../app-state.typedefs";
import { AnyAction } from "@reduxjs/toolkit";
import { isStringAction } from "../general.types";
import { navigateToEvent, INavigateToEventPayload } from '../action-types';
import { ITranscriptionReaderSession, IEventInfo } from "../shared/shared-interfaces";
import { updateObjectProperty } from "../properties.functions";

/** Reducer to handle "event" messages. */
export const eventReducer = function (state: IAppState, action: AnyAction): IAppState {
    // Ensure we're the right type of action.
    if (isStringAction(action)) {
        switch (action.type) {
            case navigateToEvent.toString():
                {
                    // Get the payload.
                    let payload: INavigateToEventPayload = action.payload;

                    // Create the event information if needed.
                    let eventInfo: IEventInfo = payload.eventInfo;
                    if (!eventInfo) {
                        eventInfo = <any>{ _id: action.payload.eventId }; // Cheating - this must be filled in by the server.
                    }

                    // Create the data to navigate to the event with.
                    let eventData: ITranscriptionReaderSession = {
                        messages: [],
                        currentMessage: '',
                        eventInfo: eventInfo
                    }

                    // Perform the update on the state.
                    let newState = updateObjectProperty(state, 'currentEvent', eventData);

                    // Update the page to navigate to.
                    newState.displayPage = 'event-view';

                    // Return the new state.
                    return newState;
                }
                break;
        }

    }

    return state;
}