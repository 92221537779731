import { IEventMessage, IEventInfo } from "./shared/shared-interfaces";
import { createAction } from "@reduxjs/toolkit";
import { AppPageTypes } from "./app-state.typedefs";
import { StringAction } from "./general.types";

/** Returns a new "set property" action. */
export function createSetPropertyAction(propertyPath: string, newValue: any): { type: 'set-value', payload: { propertyPath: string, newValue: any } } {
    return {
        type: 'set-value',
        payload: {
            propertyPath,
            newValue
        }
    };
}

export interface IChangePagePayload {
    pageName: AppPageTypes
}
export const changePage = createAction<IChangePagePayload>('change-page');

export interface IUpdateMessagePayload {
    message: IEventMessage;
}
export const updateMessage = createAction<IUpdateMessagePayload>('update-message');

export interface IAddMessagePayload {
    message: IEventMessage;
}
export const addMessage = createAction<IAddMessagePayload>('add-message');

export interface ISetMessagesPayload {
    messages: IEventMessage[];
}
export const setMessages = createAction<ISetMessagesPayload>('set-messages');

export interface INavigateToEventPayload {
    /** Either this or the event info must be provided. */
    eventId?: string;
    /** Either this or the eventId info must be provided. */
    eventInfo?: IEventInfo;
}
export const navigateToEvent = createAction<INavigateToEventPayload>('navigate-to-event');