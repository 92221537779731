import { Socket } from './socket-io.typedefs';
import io from 'socket.io-client';

/** Array of sockets that have been created, so they don't
 *   get recreated on subsequent calls to io(...). */
const socketList: { [id: string]: Socket } = {
};

/** Returns the singleton instance of the socket.io Socket. */
export function getSocket(namespace: string = '/'): Socket {
    if (namespace == undefined || namespace == '') {
        namespace = '/';
    }
    // See if we have that namespace, and return it if we do.
    let ret = socketList[namespace];
    if (ret) {
        return ret;
    }

    // Doesn't exist - create it, add it, and return it.
    ret = io(namespace);
    socketList[namespace] = ret
    return ret;
}