import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './EnterEvent.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConversationDataClient } from '../code-base/conversation-data-client';
import { createSetPropertyAction, changePage, navigateToEvent } from '../code-base/action-types';
import { ITranscriptionReaderSession } from '../code-base/shared/shared-interfaces';
import { appSlice } from '../code-base/reducers/app-state.reducer';

interface IEnterEventState {
    eventCode: string;
}
export interface IEnterEventProps { }

export class EnterEvent extends React.Component<IEnterEventProps, IEnterEventState>{
    constructor(props: IEnterEventProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        this.state = { eventCode: '' };
    }

    onStoreChanged(): void {

    }

    private unsubscribeFromStore: Unsubscribe;

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    componentWillUnmount(): void {
        this.unsubscribeFromStore();
    }

    componentDidMount(): void {
        this.mounted = true;
    }

    /** Called when the user types in the event code. */
    onEventCodeChanged(newCode: string): void {
        if (!this.mounted) {
            return;
        }

        this.setState({ ...this.state, eventCode: newCode });
    }

    /** If an error occurs, this is the message to show. */
    errorMessage?: string;

    /** Called when the user clicks the submit button. */
    onSubmit(): void {
        // Create a client to work with.
        let client = new ConversationDataClient();

        // Set the event on the state.
        client.getEventIdFromCode(this.state.eventCode).then(result => {
            if (result) {
                store.dispatch(navigateToEvent({ eventInfo: result }));
            } else {
                this.errorMessage = 'No event exists with that event code.';
            }
        });
    }

    render(): React.ReactNode {
        return (
            <div className="EnterEvent">
                <h3>Please Enter Your Event Code</h3>

                <div className="event-field">
                    <label>Code:</label>
                    <InputText value={this.state.eventCode} onChange={e => this.onEventCodeChanged((e.target as HTMLInputElement).value)} />
                    <Button label="Submit" onClick={e => this.onSubmit()} />
                </div>

                {this.errorMessage != null ?
                    <div id="error-message">
                        {this.errorMessage}
                    </div>
                    : null}
            </div>
        );
    }
}