import React from 'react';
import { store } from '../code-base/app-state';
import './CreateEvent.scss';
import { updateObjectProperty } from '../code-base/properties.functions';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ConversationDataClient } from '../code-base/conversation-data-client';
import { createSetPropertyAction } from '../code-base/action-types';
import { Unsubscribe } from '@reduxjs/toolkit';

interface ICreateEventState {
    /** If editing an existing event, this is the ID. */
    eventId?: string;

    /** The event ID we need to edit, as indicated on the app state. */
    storeEventId?: string;

    newEventName: string;
    newEventCode: string;
    newEventContent: string;
}
export interface ICreateEventProps {
}

export class CreateEvent extends React.Component<ICreateEventProps, ICreateEventState>{
    constructor(props: ICreateEventProps) {
        super(props);

        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        let appState = store.getState();

        this.state = {
            newEventCode: '',
            newEventContent: '',
            newEventName: '',
            storeEventId: appState.editConversationId
        };
    }

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }

        let appState = store.getState();

        this.setState(p => {
            let res = { ...p, storeEventId: appState.editConversationId };
            return res;
        }, () => {
            // Get the event locally.
            this.fetchSelectedEvent();
        });
    }

    private unsubscribeFromStore: Unsubscribe;

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.unsubscribeFromStore();
    }

    /** Stores the last event ID on this editor, so we can update if it changes. */
    private lastEventId: string;

    componentDidUpdate(prevProps: ICreateEventProps, prevState: ICreateEventState) {
        // If the event IDs do not match, then update them.
        if (this.state.eventId !== this.state.storeEventId) {
            this.fetchSelectedEvent();
        }
    }

    /** Updates the state to reflect the data for the eventId in the props. */
    private fetchSelectedEvent(): Promise<void> {
        // Create a client for the update.
        let client = new ConversationDataClient();

        // Make the state match so we don't perform multiple updates.
        this.setState(prevState => ({ ...prevState, eventId: this.state.storeEventId }));

        if (this.state.storeEventId != null) {

            // Perform the update.
            return client.getFullEvent(this.state.storeEventId).then(result => {

                if (result != null) {

                    // Update the state.
                    this.setState(prevState => ({
                        ...prevState,
                        newEventCode: result.code,
                        newEventContent: result.content,
                        newEventName: result.name
                    }));
                }
            });
        }

        return Promise.resolve();
    }

    /** Updates the current event on the server. */
    private updateEventOnServer(): Promise<void> {
        // Create a client to do the work for use.
        let client = new ConversationDataClient();

        let props = this.props;
        let state = this.state;

        // Do the update.
        let res = client.updateEvent({
            _id: state.storeEventId,
            content: state.newEventContent,
            name: state.newEventName,
            code: state.newEventCode
        });

        return res.then(() => {
            // Update the event list.
            client.getConversationList().then(listing => {
                // Update the application state with this list.
                store.dispatch(createSetPropertyAction('eventList', listing));
            });
        });
    }

    /** Updates a specified property on the state, to a new specified value. */
    private updateStateProperty(property: keyof ICreateEventState, newValue: string): void {
        let newState = updateObjectProperty(this.state, property, newValue);
        this.setState(newState);
    }

    /** Called when the user clicks the "create" button. */
    private createEvent(): void {
        // Create the client to connect to the server.
        let client = new ConversationDataClient();

        // Send the new event to the server.
        client.createConversation(this.state.newEventName, this.state.newEventCode, this.state.newEventContent).then(info => {
            // Update the conversation list.
            client.getConversationList().then(newList => {
                store.dispatch(createSetPropertyAction('eventList', newList));
            });
        });

        // Clear the state for this control.
        this.setState({
            newEventCode: '',
            newEventContent: '',
            newEventName: ''
        });
    }

    /** Returns a boolean value indicating whether or not the create button is disabled. */
    private isButtonDisabled(): boolean {
        function hasContent(val: string): boolean {
            return val != null && val.trim() !== '';
        }

        return ![
            this.state.newEventCode,
            this.state.newEventContent,
            this.state.newEventName
        ].every(v => hasContent(v));
    }

    render(): React.ReactNode {
        if (this.state == null) {
            return null;
        }

        const isUpdateMode = this.state.eventId != null && this.state.eventId.trim() !== '';

        return (
            <div className="CreateEvent">
                <div className="control-stack">
                    <label>Event Name</label>
                    <InputText value={this.state.newEventName} onChange={e => this.updateStateProperty('newEventName', (e.target as HTMLInputElement).value)} />

                    <label>Event Code</label>
                    <InputText value={this.state.newEventCode} onChange={e => this.updateStateProperty('newEventCode', (e.target as HTMLInputElement).value)} />

                    <label>Event Content</label>
                    <InputTextarea value={this.state.newEventContent} onChange={e => this.updateStateProperty('newEventContent', (e.target as HTMLInputElement).value)} />

                    <div>
                        {isUpdateMode
                            ? <Button label="Update" onClick={e => this.updateEventOnServer()} disabled={this.isButtonDisabled()} />
                            : <Button label="Create" onClick={e => this.createEvent()} disabled={this.isButtonDisabled()} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}