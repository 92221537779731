

export const S_START_TRANSCRIBE_SESSION = 'start-transcribe';
export const S_END_TRANSCRIBE_SESSION = 'end-transcribe';

export const S_START_LISTEN_SESSION = 'start-listen';
export const S_END_LISTEN_SESSION = 'end-listen';

export const S_MSG_NEW_MESSAGE = 'new-message';
export const S_MSG_TRANSCRIBER_TYPING = 'transcriber-typing';

export const S_MSG_UPDATE_MESSAGE = 'update-message';

export const S_MSG_CLEAR_MESSAGE = 'clear-message';
export const S_GET_FULL_FEED = 'get-full-feed';
export const S_FULL_FEED = 'full-feed';
export const S_SUBSCRIBE_TO_FEED = 'subscribe-to-feed';
export const S_SUBSCRIPTION_READY = 'subscription-ready';
export const S_UNSUBSCRIBE_FROM_FEED = 'unsubscribe-from-feed';
export const S_MSG_UPDATE_FEED = 'update-feed';

export const S_PING = 'ping-feed';

export const S_GET_ALL_LOGS = 'get-all-logs';
export const S_LOG_ITEM_ADDED = 'log-item-added';
export const S_SUBSCRIBE_TO_LOGS = 'subscribe-to-logs';
export const S_UNSUBSCRIBE_FROM_LOGS = 'unsubscribe-from-logs';