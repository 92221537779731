import React from 'react';
import { store } from '../code-base/app-state';
import { Unsubscribe } from '@reduxjs/toolkit';
import './EventView.scss';
import { ITranscriptionReaderSession } from '../code-base/shared/shared-interfaces';
import { MessageList } from '../MessageList/MessageList';

interface IEventViewState {
    currentEventInfo: ITranscriptionReaderSession;
}
export interface IEventViewProps { }

export class EventView extends React.Component<IEventViewProps, IEventViewState>{
    constructor(props: IEventViewProps) {
        super(props);
        this.unsubscribeFromStore = store.subscribe(() => this.onStoreChanged());

        // Setup the state.
        let appState = store.getState();
        this.state = { currentEventInfo: appState.currentEvent };
    }
    
    onStoreChanged(): void {
        if (!this.mounted) {
            return;
        }

        let appState = store.getState();
        this.setState(p => ({ ...this.state, currentEventInfo: appState.currentEvent }));
    }

    componentDidMount(): void {
        this.mounted = true;
    }

    /** Boolean value indicating whether or not this component has mounted. */
    private mounted = false;

    /** Flag indicating whether or not the page is loading... really this means the page doesn't have an event _id. */
    get isLoading(): boolean {
        return this.state.currentEventInfo == null ||
            this.state.currentEventInfo.eventInfo == null ||
            this.state.currentEventInfo.eventInfo._id == null;
    }

    private unsubscribeFromStore: Unsubscribe;

    componentWillUnmount(): void {
        this.unsubscribeFromStore();
    }

    /** The view to show when there is actual data. */
    getActualView(): React.ReactNode {
        return <React.Fragment>
            <h3>Event: {this.state.currentEventInfo.eventInfo.name} </h3>
            <MessageList eventId={this.state.currentEventInfo.eventInfo._id} />
        </React.Fragment>
    }

    render(): React.ReactNode {
        return (<div className="EventView">
            {this.isLoading
                ? <div>Loading...</div>
                : this.getActualView()}
        </div>)
    }
}